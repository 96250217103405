import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import {
  QuestionState,
  VideoInterviewAppointmentInfoState,
  ErrorType,
  VideoQuestionAnswerType,
} from '../../constants/types';
import VideoInterviewService from '../../services/VideoInterview';

/* eslint-disable no-param-reassign */
export interface QuestionPayloadState {
  code: string;
  question_id: number | null;
  answer_lang?: string;
  content?: string;
}

export interface VideoQuestonState {
  loading: boolean;
  question: QuestionState | null;
  error: ErrorType | null | undefined;
  info: VideoInterviewAppointmentInfoState | null;
  answerPayload: VideoQuestionAnswerType | {};
}

const initialState: VideoQuestonState = {
  loading: false,
  question: null,
  error: null,
  info: null,
  answerPayload: {},
};

export const getVideoInterviewInfoAction = createAsyncThunk<
  any,
  string,
  {
    rejectValue: ErrorType;
  }
>(
  'videoQuestion/getVideoInterviewInfoAction',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await VideoInterviewService.doGetVideoInterviewInfo(
        payload,
      );
      if (response.success) {
        return response.results;
      }
      return rejectWithValue({
        message: response.message,
      });
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({
        message: err.response.data,
      });
    }
  },
);

export const createVideoQuestionAction = createAsyncThunk<
  any,
  QuestionPayloadState,
  {
    rejectValue: ErrorType;
  }
>(
  'videoQuestion/createVideoQuestionAction',
  async (payload: QuestionPayloadState, { rejectWithValue }) => {
    try {
      const response =
        await VideoInterviewService.doCreateVideoInterviewQuestion(payload);
      if (response.success) {
        return response.results;
      }
      return rejectWithValue({
        message: response.message,
      });
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({
        message: err.response.data,
      });
    }
  },
);

export const videoQuestionSlice = createSlice({
  name: 'videoQuestion',
  initialState,
  reducers: {
    clearAction: (state) => {
      state.loading = false;
      state.error = null;
    },
    setAnswerPayload: (state, action) => {
      state.answerPayload = action.payload;
    },
    clearAnswerPayload: (state) => {
      state.answerPayload = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVideoInterviewInfoAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVideoInterviewInfoAction.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
      })
      .addCase(getVideoInterviewInfoAction.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error as ErrorType;
        }
      })
      .addCase(createVideoQuestionAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(createVideoQuestionAction.fulfilled, (state, action) => {
        state.loading = false;
        state.question = action.payload;
      })
      .addCase(createVideoQuestionAction.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error as ErrorType;
        }
      });
  },
});

export const { clearAction, clearAnswerPayload, setAnswerPayload } =
  videoQuestionSlice.actions;

export const selectLoading = (state: RootState) => state.videoQuestion.loading;
export const selectQuestion = (state: RootState) =>
  state.videoQuestion.question;
export const selectAnswerPayload = (state: RootState) =>
  state.videoQuestion.answerPayload;
export const selectInfo = (state: RootState) => state.videoQuestion.info;
export const selectError = (state: RootState) => state.videoQuestion.error;

export default videoQuestionSlice.reducer;
